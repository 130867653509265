import React from 'react'
import Layout, { LAYOUT_TYPES } from '../modules/Layout'
import { graphql } from 'gatsby'
import Main from '../modules/Main'
import useSeo from '../hooks/useSeo'
import { TEMPLATE_TYPES } from '../common/constants'

const DATA_MAP = {
  title: ['name'],
  image: ({ featureImage }) => {
    return featureImage?.fluid?.src || featureImage?.gatsbyImageData?.images?.fallback?.src
  },
  breadcrumb: ['name'],
}

const getLayout = (data, helmetProps) => {
  return [
    ...data.layout,
    {
      __typename: LAYOUT_TYPES.HELMET,
      ...helmetProps,
    },
  ]
}

const DestinationTemplate = (props) => {
  const {
    data: { contentfulDestinations },
  } = props

  const url = props.location.href
  const seoProps = useSeo(contentfulDestinations, DATA_MAP, TEMPLATE_TYPES.DESTINATION)
  const layout = getLayout(contentfulDestinations, seoProps)

  return (
    <Main {...props} path={url}>
      <Layout layout={layout} path={url} />
    </Main>
  )
}

export default DestinationTemplate

export const destinationQuery = graphql`
  query DestinationBySlug($slug: String!) {
    contentfulDestinations(slug: { eq: $slug }) {
      id
      slug
      name
      structuredData {
        altText
        description {
          description
        }
        headline
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      featureImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      layout {
        __typename
        ... on ContentfulHero {
          ...Hero
        }
        ... on ContentfulImage {
          id
          loading
          altText
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        ... on ContentfulLayoutLeftRight {
          id
          ...LayoutLeftRight
        }
        ... on ContentfulVideo {
          id
          ...Video
        }
        ... on ContentfulLayoutGallery {
          id
          ...LayoutGallery
        }
        ... on ContentfulCard {
          id
          ...Card
        }
        ... on ContentfulQuote {
          id
          ...Quote
        }
        ... on ContentfulLayoutAccordion {
          ...LayoutAccordion
        }
      }
    }
  }
`
